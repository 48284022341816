import React, { useMemo } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import UtilButtonV2 from '@/v2/storyblok/utils/UtilButtonV2'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import Icon from '@/components/Icon'
import classNames from 'classnames'

type Props = {
  blok: SbBlokData & ISbSectionTwoColumnActionCardV2
  hasTopPadding?: boolean
}

const SectionTwoColumnActionCardV2 = ({ blok, hasTopPadding = true }: Props) => {
  const hasItems = !blok.hide_items && !!blok.items.length
  const isBackgroundDark = blok.background === 'green-dark'
  const textColor = isBackgroundDark ? 'text-white' : 'text-v2-text-primary'
  const background = blok.background ? `bg-v2-background-${blok.background}` : 'bg-v2-background-white'
  const borderColor = isBackgroundDark ? 'border-white' : 'border-v2-text-primary'
  const iconColor = isBackgroundDark ? 'white' : 'v2-text-primary' // [&_path]:!fill-v2-text-primary needs to be safelisted

  const borderClasses = useMemo(() => {
    if (!blok.border) return ''

    switch (blok.background) {
      case 'green-dark': {
        return 'border border-v2-foreground-static-tertiary' // xl:border-v2-foreground-static-tertiary needs to be safelisted
      }
      case 'beige':
      case 'blue':
      case 'white':
      default: {
        return 'border border-text-v2-text-primary' // xl:border-text-v2-text-primary needs to be safelisted
      }
    }
  }, [blok.background, blok.border])

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames('pb-20 md:px-8 lg:pb-32', {
        'pt-20 lg:pt-32': hasTopPadding,
      })}
    >
      <div className={`rounded-5xl xl:${background} xl:${borderClasses}`}>
        <div className="container-v2 mx-auto">
          <div className={`${background} ${textColor} ${borderClasses} rounded-5xl p-7 md:p-20 xl:border-none`}>
            <div className="flex flex-col gap-7 lg:flex-row lg:gap-20">
              <div className={`flex flex-col justify-center ${hasItems ? 'lg:w-4/6' : ''}`}>
                {blok.kicker && <span className={`heading-6 text-v2-text-primary ${textColor}`}>{blok.kicker}</span>}
                <h2 className="heading-2 mb-6 whitespace-pre-line">{blok.title}</h2>
                {!!blok.text && <p className="body-1-bold mb-6 whitespace-pre-line">{blok.text}</p>}

                {/* List of benefits */}
                {!!blok.benefits?.length && (
                  <ul className="mb-6 lg:mb-20">
                    {blok.benefits.map((benefit, index) => (
                      <li className="flex items-center gap-3 py-3" key={index}>
                        <Icon
                          name={'arrow-right-v2'}
                          width={12}
                          height={12}
                          className={`shrink-0 [&_path]:!fill-${iconColor}`}
                        />
                        <span className="body-2-bold">{benefit.title} </span>
                      </li>
                    ))}
                  </ul>
                )}

                {/* Button */}
                {!!blok.button?.length && (
                  <div className="sm:w-fit">
                    {blok.button.map((blok, index) => (
                      <UtilButtonV2 key={index} blok={blok} isIconRightOnMobileEnabled={true} />
                    ))}
                  </div>
                )}
              </div>

              {/* list items for right side of action card */}
              {hasItems && (
                <div
                  className={`${borderColor} lg:py-13 mt-8 border-t pt-8 lg:m-0 lg:my-14 lg:border-l lg:border-t-0 lg:py-14 lg:pl-20 xl:pl-40`}
                >
                  <ul className="flex flex-col justify-center md:flex-row lg:flex-col">
                    {blok.items?.map((item, index) => (
                      <li className="mx-4 mb-7 flex flex-col items-center gap-3 last:mb-0 lg:mb-20 lg:items-start" key={index}>
                        {item.icon?.filename && (
                          <BaseImageV2
                            src={item.icon.filename}
                            alt={item.icon.alt}
                            breakPointsWidthMap={{ 0: 32 }}
                            className="w-8"
                          />
                        )}
                        {item.title && <h4 className="heading-5">{item.title}</h4>}
                        {item.text && <span className="body-2 text-center lg:text-left">{item.text}</span>}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionTwoColumnActionCardV2
